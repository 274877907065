<template>
    <!--详情页与审核页相同-->
    <div class="CheckDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="form" :model="form" :rules="rules" label-width="80px" width="auto" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="盘点编码" label-width="100px">
                                <span style="padding-left: 10px">{{ code }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="机构类型" label-width="100px">
                                <el-input v-model="form.deptTypeName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="机构名称" label-width="100px">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建人" label-width="100px">
                                <el-input v-model="form.creator" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建时间" label-width="100px">
                                <el-input v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="盘点类型" prop="verifyFlag">
                                <el-select v-model="form.verifyFlag" placeholder="请选择">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <div
                                v-if="form.verifyFlag === false"
                                style="color: #f56c6c; font-size: 12px; line-height: 1"
                            >
                                非营业盘点不同步收银机离线数据，离线数据未同步会导致盈亏数量偏差。
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="备注-盘点单" label-width="100px">
                                <el-input
                                    readonly
                                    v-model="form.remark"
                                    type="textarea"
                                    style="width: 650px"
                                    :maxlength="200"
                                    :minlength="1"
                                    show-word-limit
                                    placeholder="字数不超过200"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="备注-盘点" label-width="100px">
                                <el-input
                                    v-model="form.checkRemark"
                                    type="textarea"
                                    style="width: 650px"
                                    :maxlength="200"
                                    :minlength="1"
                                    show-word-limit
                                    placeholder="字数不超过200"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="padding: 20px 10px">
                <el-button type="primary" @click="handleAudit" v-if="hasPrivilege('menu.stock.check.review')"
                    >下载盘点单
                </el-button>
                <span v-if="inventoryType === '复盘'" style="background: lightpink; padding: 5px; margin-left: 27px"
                    >请关注在初盘完成至提交复盘数量期间有出入库记录的商品，出入库数量需体现在复盘数量中，最终根据盈亏数量变更库存</span
                >
                <el-dialog
                    title="审核业务单"
                    :visible.sync="reviewVisible"
                    width="30%"
                    :before-close="handleClose"
                    top="30vh"
                >
                    <div>
                        <el-form>
                            <el-form-item label="审核意见：">
                                <el-radio-group v-model="radio">
                                    <el-radio :label="0">通过</el-radio>
                                    <el-radio :label="1">拒绝</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="审核备注：">
                                <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 2 }"
                                    maxlength="200"
                                    placeholder="请输入内容"
                                    v-model="textarea2"
                                    style="width: 80%"
                                >
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="reviewVisible = false">取 消</el-button>
                        <el-button type="primary" @click="reviewVisible = false">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.detailParams"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
                v-loading="loading"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.bars" label="条码" width="200">
                    <template slot-scope="scope">
                        <span>{{
                            skuByGoodsCode(scope.row.goodsCode)
                                .bars.map((d) => d)
                                .join(' / ')
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                    </template>
                </el-table-column>
                <el-table-column prop="fastBar" label="助记码" width="100">
                    <template slot-scope="scope">
                        {{ goodsByCode(scope.row.goodsCode).fastBar }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="120">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.category" label="类目" width="120">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.unit" label="单位" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.brand" label="品牌" width="120">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="snapshotCounts"
                    label="初盘快照数量"
                    width="150"
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot="header">
                        <span>初盘快照数量</span>
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content">
                                本字段统计数据为门店实际库存数量的快照，实际库存=库存总数-借出商品数量
                            </div>
                            <i class="el-icon-info"></i>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        {{ form.firstCheckSnapshotCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column prop="firstCheckCount" label="初盘数量" width="140">
                    <template slot-scope="scope">
                        <div v-if="inventoryType === '初盘'">
                            <ef-stock-biz-count-input
                                v-model="scope.row.firstCheckCount"
                                :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                            />
                        </div>
                        <div v-if="inventoryType === '复盘'">
                            {{ form.firstCheckCounts[scope.$index] }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.brand" label="库存变动/盘点差异" width="140" v-if="inventoryType == '复盘'">
                    <template slot="header">
                        <el-popover placement="bottom" title="库存变动/盘点差异" width="200" trigger="click">
                            <p>是（满足任一条件）</p>
                            <p>1. 提交盘点单至提交初盘结果期间有出入库记录</p>
                            <p>2. 初盘快照数量 ≠ 初盘数量</p>
                            <span slot="reference">库存变动/盘点差异 <i class="el-icon-info"></i></span>
                        </el-popover>
                    </template>
                    <template slot-scope="scope">
                        {{
                            form.firstCheckSnapshotCounts[scope.$index] !== form.firstCheckCounts[scope.$index] ||
                            (form.firstCheckBizStockChangeJsons[scope.$index]
                                ? form.firstCheckBizStockChangeJsons[scope.$index].length
                                : form.firstCheckBizStockChangeJsons[scope.$index])
                                ? '是'
                                : '否'
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="secondCheckCount" label="复盘数量" width="140" v-if="inventoryType == '复盘'">
                    <template slot-scope="scope">
                        <ef-stock-biz-count-input
                            v-model="scope.row.secondCheckCount"
                            :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                        />
                    </template>
                </el-table-column>
            </el-table>
            <div class="btnBox">
                <el-button :loading="saveLoading" @click="handleSave(true)" size="medium">暂存</el-button>
                <el-button type="primary" :loading="saveLoading" @click="handleSave(false)" size="medium"
                    >提交</el-button
                >
            </div>
        </el-card>
        <pick-up-goods ref="pickupGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
    </div>
</template>
<script>
import PickUpGoods from 'components/PickUpGoods';
import StockBizCommon from 'js/StockBizCommon';
import GoodsCommon from 'js/GoodsCommon';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import UrlUtils from 'js/UrlUtils';
import { MessageBox } from 'element-ui';

export default {
    name: 'CheckReview',
    components: { EfStockBizCountInput, PickUpGoods },
    props: {
        code: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            baseUrl: this.$efApi.stockCheckApi.baseUrl,
            queryFormData: {
                code: '',
                deptCode: '',
                deptName: '',
                deptTypeName: '',
                remark: '',
                createTime: '',
                creator: '',
                reviewStatus: '',
                details: [],
                updateTime: '',
            },
            form: {
                code: '',
                deptCode: '',
                checkRemark: '',
                detailParams: [],
                delCodes: [],
                verifyFlag: '',
            },
            options: [
                {
                    value: true,
                    label: '营业盘点',
                },
                {
                    value: false,
                    label: '非营业盘点',
                },
            ],
            rules: {
                verifyFlag: [{ required: true, message: '请选择盘点类型', trigger: 'change' }],
            },
            detailParamPropertyArr: ['codes', 'goodsCode', 'counts', 'snapshotCounts'],
            goodsDetailMap: new Map(),
            loading: true,
            reviewVisible: false,
            radio: 0,
            textarea2: '',
            AAA: '',
            saveLoading: false,
            inventoryType: null,
        };
    },
    mounted() {
        this.inventoryType = this.$route.params.inventoryType;
        (async () => {
            //查询数据
            const rst = await this.$efApi.stockCheckApi.detail(this.code);
            // Util.copyProperties(rst, this.form);
            this.form = rst;
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(rst, this.detailParamPropertyArr);
            arr.detailParams = arr.detailParams.map((i) => {
                i.firstCheckCount = null;
                return i;
            });
            this.form.detailParams = arr.detailParams;
            this.goodsDetailMap = arr.goodsDetails;
            this.form.firstCheckBizStockChangeJsons = this.form.firstCheckBizStockChangeJsons.map((item) => {
                return JSON.parse(item);
            });
            this.form.detailParams.forEach((item, index) => {
                item.firstCheckCount = this.form.firstCheckCounts[index];
                item.secondCheckCount = this.form.secondCheckCounts[index];
            });
            this.loading = false;
        })();
    },
    methods: {
        handleAudit() {
            MessageBox.confirm('下载盘点单', '提示', {
                confirmButtonText: '下载',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(async () => {
                    const params = {
                        code: this.code,
                        bizType: this.inventoryType === '初盘' ? 0 : 1,
                        deptName: this.form.deptName,
                    };
                    const date = new Date();
                    const mon = date.getMonth() + 1;
                    const day = date.getDate();
                    const currDate =
                        date.getFullYear() + '-' + (mon < 10 ? '0' + mon : mon) + '-' + (day < 10 ? '0' + day : day);
                    const fileName = this.form.deptName + '盘点单' + currDate;
                    UrlUtils.Export(this, fileName, '/stock/check/downloadStockCheck', params);
                })
                .catch(() => {
                    //抛出异常，中断后续代码执行，且需要return Promise
                    return Promise.reject('取消操作/关闭确认');
                });
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
                this.$refs.pickupGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        handleClose() {},
        handleSave(type) {
            this.$refs.form.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length === 0) {
                    this.$message.error('请选择盘点商品');
                    return false;
                }
                if (this.inventoryType == '复盘') {
                    const flag = this.form.detailParams.some((i) => {
                        if (i.firstCheckCount === null && i.secondCheckCount === null) {
                            return true;
                        }
                    });
                    if (flag) {
                        this.$message.error('初盘数量和复盘数量不能同时为空');
                        return false;
                    }
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }

                const attribute = this.inventoryType == '初盘' ? 'firstCheckCount' : 'secondCheckCount';
                const reusltArr = this.form.detailParams.map((i) => {
                    return {
                        detailCode: i.codes,
                        goodsCode: i.goodsCode,
                        [attribute]: i[attribute], // 初盘数量
                    };
                });
                const params = {
                    code: this.code,
                    deptCode: this.form.deptCode,
                    checkRemark: this.form.checkRemark,
                    verifyFlag: this.form.verifyFlag,
                    goods: reusltArr,
                };
                if (type) {
                    const requestMethod =
                        this.inventoryType == '初盘'
                            ? 'initialOfferUpdateTemporaryStorage'
                            : 'replayUpdateTemporaryStorage';
                    // 暂存
                    await this.$efApi.stockCheckApi[requestMethod](params).finally(() => {
                        this.saveLoading = false;
                    });
                    this.goBackAndReload();
                } else {
                    // 提交
                    const requestMethod =
                        this.inventoryType == '初盘'
                            ? 'initialOfferUpdateNewlyBuildSubmit'
                            : 'replayUpdateNewlyBuildSubmit';
                    await this.$efApi.stockCheckApi[requestMethod](params).finally(() => {
                        this.saveLoading = false;
                    });
                    this.goBackAndReload();
                }
                this.saveLoading = true;
            });
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
.btnBox {
    margin-top: 40px;
    margin-bottom: 100px;
}
.el-form-item__error {
    left: 0;
}
</style>
